<template>
  <LayoutThumbnailComponent
    :linkName="linkToPogrammeSlug ? 'programme-slug' : 'events-slug'"
    :localizedData="localizedData"
    :linkType="linkType"
    :class="$style.root"
    :image="coverImage"
    :clusterBackgroundColor="clusterBackgroundColor"
    :data-disabled="disabled ? '' : null"
  >
    <template
      #tags
      v-if="cluster?.titles || categories?.length"
    >
      <SharedLinkToClusterComponent
        v-if="cluster"
        :data="cluster"
        data-narrow
        linkType="dynamic"
        sizeOption="tiny"
      />

      <LayoutPillComponent
        v-for="category in categories"
        :key="category?.id"
        componentType="link"
        sizeOption="tiny"
        :to="{
          name: linkToPogrammeSlug ? 'programme' : 'events',
          query: categoryQuery(category?.id)
        }"
      >
        {{ category?.title }}
      </LayoutPillComponent>
    </template>

    <div :class="$style.title">
      {{ title }}
      <span
        v-if="participants"
        :class="$style.participants"
      >
        {{ participants }}
      </span>
    </div>

    <CoreDateTimeRangeComponent
      :dateStart="attributes?.date_time_start"
      :dateEnd="attributes?.date_time_end"
      :class="$style.dateRange"
    />

    <div
      v-if="venue"
      :class="$style.venue"
    >
      {{ venue }}
    </div>

    <div
      v-else-if="eventLocationAddresses"
      :class="$style.venue"
    >
      {{ eventLocationAddresses }}
    </div>

    <div
      v-else-if="focusWeekAddress"
      :class="$style.venue"
    >
      {{ focusWeekAddress }}
    </div>
  </LayoutThumbnailComponent>
</template>

<script setup>
const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp

import { uniq } from 'lodash-es'
const route = useRoute()

const props = defineProps({
  data: Object,
  linkType: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'dynamic'].includes(value)
  },
  disabled: {
    type: Boolean,
    default: false
  },
  linkToPogrammeSlug: {
    type: Boolean,
    default: false
  }
})

const attributes = computed(() => {
  return baseStrapiGetAttributes(props?.data)
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(attributes?.value)
})

const title = computed(() => i18nGetLocalizedTitle(localizedData?.value))

const coverImage = computed(() => localizedData.value?.cover_image?.data?.attributes)

const participants = computed(() => {
  return attributes?.value?.participant_entries?.data?.map((participant_entry) => {
    return participant_entry?.attributes?.title
  })?.filter(Boolean)?.join(', ')
})

const venue = computed(() => {
  const localizedData = i18nGetLocalizedData(attributes?.value?.venue_entry?.data?.attributes)
  const venue = i18nGetLocalizedString(localizedData?.titles)
  const city = i18nGetLocalizedString(localizedData?.city_entry?.data?.attributes?.titles)
  return [venue?.trim(), city?.trim()]?.filter(Boolean)?.join(', ')
})

const cluster = computed(() => {
  const clusterFromVenueData = i18nGetLocalizedData(localizedData?.value?.venue_entry?.data?.attributes)?.city_entry?.data?.attributes?.cluster_entry?.data?.attributes
  const clusterFromEventLocationData = localizedData?.value?.event_locations?.data?.map(i => i?.attributes?.cluster_entry?.data?.attributes)?.filter(Boolean)?.[0]
  const clusterFromFocusWeekData = localizedData?.value?.focus_week_entry?.data?.attributes?.city_entry?.data?.attributes?.cluster_entry?.data?.attributes
  return clusterFromVenueData || clusterFromEventLocationData || clusterFromFocusWeekData
})

const clusterBackgroundColor = computed(() => {
  return cluster?.value?.background_color
})

const categories = computed(() => {
  return localizedData?.value?.event_categories?.data?.map((category) => {
    return {
      title: i18nGetLocalizedString(category?.attributes?.titles),
      id: category?.id
    }
  })?.filter(i => i?.title)
})

const categoryQuery = (id) => {
  const categories = route?.query?.categories?.split(',')?.filter(Boolean) || []
  return {
    ...route?.query,
    categories: uniq([...categories, String(id)])?.join(',')
  }
}

const eventLocationAddresses = computed(() => {
  const addresses =  localizedData?.value?.event_locations?.data?.map(i => {
    return i18nGetLocalizedString(i?.attributes?.address)
  })?.filter(Boolean)

  if (addresses?.length && addresses?.length < 4) {
    return addresses?.join(', ')
  } else if (addresses?.length) {
    return $i18n.t('shared.location', { count: addresses?.length })
  }
})

const focusWeekAddress = computed(() => {
  return i18nGetLocalizedString(localizedData?.value?.focus_week_entry?.data?.attributes?.address)
})
</script>

<style module>
.root {
  --layout--thumbnail--aspect-ratio: .65;
}

.title {
  composes: font-size-small from global;
  margin-top: var(--unit--spacer);
}

.participants {
  color: var(--color--gray);
}

.dateRange {
  composes: font-size-x-small from global;
  color: var(--color--blue);
  display: block;
}

.venue {
  composes: font-size-x-small from global;
}
</style>
